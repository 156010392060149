import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isoWeek from 'dayjs/plugin/isoWeek';
import { Table } from 'semantic-ui-react';
import _ from 'lodash';

import UserStatus from '../UserStatus';
import Loader from '../Loader';

import HeaderTable from './HeaderTable';
import ActivitiesModal from './ActivitiesModal';
import AttendanceModal from './AttendanceModal';
import AttendanceError from './AttendanceError';
import AttendancePagination from './Pagination';
import style from './Attendance.module.scss';

import array from 'utils/array';
import { getUserStatusProps, getUserInformation } from 'utils/attendance';
import { APP_CONTENT, DEFAULT_PAGE } from 'utils/constants';

import { useAttendanceContext, useGlobalContext } from 'contexts';

import { useUser } from 'hooks/useUser';

dayjs.extend(advancedFormat);
dayjs.extend(isoWeek);

function Attendance(): JSX.Element {
  const [attendanceState, attendanceRequest] = useAttendanceContext();
  const [globalState] = useGlobalContext();
  const { lastUserEvent } = globalState;
  const {
    selectedGroup,
    selectedGroupUser,
    error,
    attendancesList,
    meta,
    week,
    pagination,
    project,
    date: { startDate, endDate },
  } = attendanceState;
  const {
    getAttendances,
    getUserTimesheet,
    cleanUserTimesheet,
    updatePagination,
    updateProject,
    getLastUserEventById,
  } = attendanceRequest;
  const { totalPages, paginationSetup } = meta;
  const { activePage, limit } = pagination;

  const [loading, setLoading] = useState(false);
  const [weekPage, updateWeekPage] = useState(0);
  const [currentUserAttendance, setCurrentUserAttendance] = useState<any>(null);
  const [attendanceDate, setAttendanceDate] = useState(currentUserAttendance?.date || dayjs().format('YYYY-MM-DD'));
  const [currentUserAct, setCurrentUserAct] = useState<any>(null);

  const { currentUserIsAdmin, currentUserIsManager, currentUser } = useUser();

  function onChangePage(event: any, pageInfo: any) {
    updatePagination({ ...pagination, activePage: pageInfo.activePage });
  }

  const setOptionValue = (e: any, optionValue: any) => {
    updatePagination({ limit: optionValue.value, activePage: DEFAULT_PAGE });
  };

  const onAddClick = async (userId: string, date: string) => {
    setCurrentUserAct({ userId, date });
  };

  const onAttendanceDetailClick = (userId: string, date: string) => {
    setAttendanceDate(date)
    setCurrentUserAttendance({ userId, date });
  };

  const onDownloadDailyClick = async (userId: string, date: string) => {
    const userInfo = getUserInformation(userId, date, project);
    await getUserTimesheet(userInfo);
  };

  const onDownloadClick = async (userId: string) => {
    const userInfo = getUserInformation(userId, { startDate, endDate }, project);
    await getUserTimesheet(userInfo);
  };

  const userStatusProps = (user: any, customWeek: any[]) => {
    const props = getUserStatusProps(user, customWeek, weekPage);
    return { ...props, onAddClick, onDownloadDailyClick, onDownloadClick, onAttendanceDetailClick };
  };

  const hasData = !_.isEmpty(attendancesList);
  const customWeek = week && array.chunkArray(week, 7);

  function handleOnDateChange(date: any) {
    setAttendanceDate(date);
  }

  async function fetchAttendances() {
    setLoading(true);
    await getAttendances();
    if (globalState.contentToShow === APP_CONTENT.ATTENDANCE) {
      cleanUserTimesheet();
    }
    setLoading(false);
  }

  async function handleOpenMarker(userId: string) {
    return getLastUserEventById(userId);
  }

  useEffect(() => {
    let isCancelled = false;

    const fetchData = async () => {
      if (isCancelled) return;

      fetchAttendances();
    };

    fetchData();

    return () => {
      isCancelled = true;
    }
  }, [startDate, pagination, selectedGroupUser, selectedGroup, project, lastUserEvent]);

  return loading ? (
    <div className={style.loader}>
      <Loader active inline="centered" size="medium" />
    </div>
  ) : (
    <div className={style.generalContainer}>
      {hasData && (
        <div className={style.attendance}>
          <div className={style.attendanceContent}>
            <Table className={style.attendanceTable}>
              <HeaderTable
                week={customWeek[weekPage]}
                limit={customWeek.length}
                currentPage={weekPage}
                onChange={(page: any) => updateWeekPage(page)}
                project={project}
                onChangeProject={(newProject: any) => updateProject(newProject)}
                selectedGroupId={selectedGroup?._id ?? ''}
                selectedUserId={selectedGroupUser[0]?._id ?? ''}
              />
              <Table.Body className={style.attendanceItemList}>
                {attendancesList.map((userAttendance: any) => (
                  <UserStatus
                    key={userAttendance.id}
                    globalUserIsAdmin={currentUserIsAdmin}
                    globalUserIsManager={currentUserIsManager}
                    globalUserId={currentUser?.profile.sub}
                    {...userStatusProps(userAttendance, customWeek[weekPage])}
                  />
                ))}
              </Table.Body>
            </Table>
          </div>
          <div className={style.attendancePagination}>
            <AttendancePagination
              activePage={activePage}
              limit={limit}
              setOptionValue={setOptionValue}
              totalPages={totalPages}
              onChangePage={onChangePage}
              paginationSetup={paginationSetup}
            />
          </div>
        </div>
      )}
      {!hasData && (
        <div className={style.noDataContainer}>
          {!loading && !error && <h3>No users found</h3>}
          {error && <AttendanceError />}
        </div>
      )}
      {currentUserAttendance && (
        <AttendanceModal
          opened={!!currentUserAttendance}
          userId={currentUserAttendance.userId}
          date={attendanceDate}
          onClose={() => {
            fetchAttendances();
            setCurrentUserAttendance(null);
          }}
          onOpenMarker={handleOpenMarker}
          onDateChange={handleOnDateChange}
        />
      )}
      {currentUserAct && (
        <ActivitiesModal
          opened={!!currentUserAct}
          date={currentUserAct.date}
          userId={currentUserAct.userId}
          globalUserId={currentUser?.profile.sub}
          project={project}
          onChangeProject={(newProject: any) => updateProject(newProject)}
          selectedGroupId={selectedGroup?._id ?? ''}
          selectedUserId={selectedGroupUser[0]?._id ?? ''}
          onClose={() => {
            fetchAttendances();
            setCurrentUserAct(null);
          }}
        />
      )}
    </div>
  );
}

export default Attendance;
