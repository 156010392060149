import Axios from 'axios';
import _ from 'lodash';

import { RequestOptions } from '../service/RequestOptions';

import { getOptionsLimit } from './paginationSetup';

function getData(params: any) {
  const data = params.data?.data;
  const totalPages = Math.ceil(data.totalRows / data.limit);
  const paginationSetup = getOptionsLimit(data.totalRows);
  return { data, totalPages, paginationSetup };
}

export async function requestWithPagination(options: Promise<RequestOptions>) {
  const cancelToken = Axios.CancelToken.source();
  const requestOptions = await (_.isFunction(options) ? options() : options);
  requestOptions.options.cancelToken = cancelToken.token;

  try {
    const data = await requestOptions.toAxios();
    return getData(data);
  } catch(error) {
    if (Axios.isCancel(error)) {
      console.warn('Request canceled:', error.message);
    } else {
      throw error;
    }
  }
}

export async function request(options: Promise<RequestOptions>) {
  const requestOptions = await (_.isFunction(options) ? options() : options);
  const data = await requestOptions.toAxios();
  return data?.data;
}
